.coontainer {
  width: 50%;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media screen and (max-width: 790px) {
  .coontainer {
    width: 80%;
  }
}
@media screen and (max-width: 480px) {
  .coontainer {
    width: 100%;
  }
}
